import axios from "axios";


let url = "https://api.corith.com/corith"
let url1 = "http://localhost:8080/corith"

export function recordVisit(page) {
    axios.get('https://api.ipify.org?format=json')
        .then(response => {
            let data = {
                page: page,
                ip: response.data.ip
            }
            axios.post(url, data)
        })
        .catch(error => {
            console.error('Error fetching IP address:', error);
        });
}

