/* eslint-disable */
import React, { useState } from "react";
import axios from "axios";
import '../styles/subscribe.css'
import {useEffect} from "react";

export const Subscribe = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [numSubs, setNumSubs] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: email,
            firstName: firstName,
            lastName: lastName
        }


        let url = ''
        if (process.env.NODE_ENV === 'development') {
            url = 'http://localhost:4304/subscribe'
        }
        if (process.env.NODE_ENV === 'production') {
            url = 'http://18.191.121.52:4304/subscribe'
        }

        if (data.email === '') {
            alert("Please fill out at least the email :)")
        } else {
            console.log(data)
            axios.post(url, data).then(res => {
                if (res.status === 200) {
                    alert("You have subscribed! Thank you :)")
                    setNumSubs(0);
                } else {
                    alert("error")
                }
            }).catch((e) => {
                if (e.response !== undefined) {
                    if (e.response.status === 409) {
                        console.log(e.response.data)
                        alert('It looks like someone is already subscribed with that email...')
                    } else if (e.request) {
                        alert('Sorry, there was an error with the server :/')
                    } else {
                        console.log('Error', e.message)
                        alert('I think you did something wrong? Don\'t worry though...you can do it :\)')
                    }
                }
            })
        }
        setEmail('');
        setFirstName('');
        setLastName('');
    }

    const  getSubs= () => {
        let url = ''
        if (process.env.NODE_ENV === 'development') {
            url = 'http://localhost:4304/subscribers'
        }
        if (process.env.NODE_ENV === 'production') {
             url = 'http://18.191.121.52:4304/subscribers'
        }
        axios.get(url).then((res) => {
            setNumSubs(res.data)
        }).catch((e) => {
            setNumSubs('dunno')
        })
    }

    useEffect(() => {
        getSubs()
    },[numSubs])

    return(
        <div className='py-16'>
            <div>
                <h1 className='text-center text-white text-7xl '> Stay up to date! </h1>
                <h4 className='text-center text-red-500 text-base mt-5'> (Currently not hooked up to backend) </h4>
                <h5 className='text-center text-purple-200 pb-5 text-lg'> If you would like to get in contact with me, send me an email at <a href="mailto: coryseb@protonmail.com"><span className='text-purple-700'>this email.</span></a></h5>
            </div>

            <div className='w-full mt-20'>
                <div className='flex justify-center'>
                    <form className="sub-form bg-gray-600 grid gap-2 justify-center p-5 text-lg sm:text-3xl" onSubmit={e => {handleSubmit(e)}}>

                        <label className="lab" htmlFor="email">Email</label>
                        <input className="input p-4 rounded-xl"  type="email" name="email" onChange={e => setEmail(e.target.value)} value={email} placeholder="email@email.com"/>

                        <label className="lab" htmlFor="firstName">First Name</label>
                        <input className="input p-4 rounded-xl"  type="text" name="firstName" onChange={e => setFirstName(e.target.value)} value={firstName} placeholder="First (optional)"/>

                        <label className="lab" htmlFor="lastName">Last Name</label>
                        <input className="input p-4 rounded-xl"  type="text" name="lastName" onChange={e => setLastName(e.target.value)} value={lastName} placeholder="Last (optional)"/>

                        <input className="input-btn bg-purple-400 rounded-xl col-start-2 col-end-3 row-start-4 row-end-5" type="submit" value="Subscribe"/>
                    </form>
                </div>

            </div>
            <h3 className='text-center text-white p-5'> Current # of Subscribers: {numSubs}</h3>
        </div>
    )
}
