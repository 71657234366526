import {SideBar} from "../../components/SideBar";
import purpDev from "../../assets/purpConstruction.png";

export function HelpfulResources() {
    return (
        <div className='backGroundLTC rounded-b-xl shadow-inner shadow-black'>
            <div className='grid lg:grid-rows-3cl lg:grid-cols-2ltc  gap-6 p-6'>

                <SideBar />

                <div className='lg:col-start-2 lg:col-end-3  rounded-full'>
                    {/*<h1 className='text-center p-5 text-3xl text-white'> Resources </h1>*/}
                    <h1 className='text-center p-5 text-6xl sm:text-7xl font-bold text-transparent bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text'> Helpful Resources </h1>

                </div>

                <div className=' rounded-2xl lg:row-start-2 lg:row-end-4'>

                    <div className="flex justify-center">
                        <img src={purpDev} alt="under development"/>
                    </div>

                    <div className='flex justify-center py-5'>
                        <div className="flex flex-col">
                            <p className="text-white">
                                While you wait check look at this course called
                            <a href="https://www.edx.org/course/cs50s-introduction-to-computer-science"> CS50 put on by Harvardx</a>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
