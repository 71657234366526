import '../styles/footer.css'

export function Footer() {
    return (
        <div className="std-shadow rounded-xl">
            <footer className="footer ">
                <p> corith.com &reg; </p>
                <p> coryseb@protonmail.com</p>
                <p><a href="/privacy" className=''>Privacy Policy</a></p>
            </footer>
        </div>
    )
}
