import {NavLink} from "react-router-dom";

export function SideBar() {

    return (
        <div className='sideBarShadow rounded-2xl lg:row-start-2 lg:row-end-3 lg:sticky top-0 '>

            <nav className='std-shadow rounded-2xl p-2 shadow-inner shadow-gray-500'>

                <ul className='grid lg:gap-10 gap-2 text-sm'>

                    <li className='hover:bg-purple-400 rounded-xl p-1'>
                        <NavLink to="/ltc" className='text-white font-bold text-lg block lg:text-left  text-center' activeClassName='active rounded-xl p-1' exact> So you Wanna Code? </NavLink>
                    </li>

                    <li className='hover:bg-purple-400 rounded-xl p-1'>
                        <NavLink to="/ltc/first" className='text-white font-bold text-lg block lg:text-left  text-center' activeClassName='active rounded-xl p-1' exact> Choosing Your First Language </NavLink>
                    </li>

                    <li className='hover:bg-purple-400 rounded-xl p-1'>
                        <NavLink to='/ltc/start' className='text-white font-bold text-lg block lg: lg:text-left  text-center text-left  text-center' activeClassName='active rounded-xl p-1' > Starter Projects </NavLink>
                    </li>

                    <li className='hover:bg-purple-400 rounded-xl p-1'>
                        <NavLink to="/ltc/wik" className='text-white font-bold text-lg block lg:text-left  text-center' activeClassName='active rounded-xl p-1' exact> Hindsight 20/20 </NavLink>
                    </li>

                    <li className='hover:bg-purple-400 rounded-xl p-1'>
                        <NavLink to="/ltc/resources" className='text-white font-bold text-lg block lg:text-left  text-center' activeClassName='active rounded-xl p-1' exact> Helpful Resources </NavLink>
                    </li>

                    <li className='hover:bg-purple-400 rounded-xl p-1'>
                        <NavLink to="/ltc/tutorials" className='text-white font-bold text-lg block lg:text-left  text-center' activeClassName='active rounded-xl p-1' exact> Tutorials! </NavLink>
                    </li>

                    <li className='hover:bg-purple-400 rounded-xl p-1'>
                        <NavLink to="/ltc/linux" className='text-white font-bold text-lg block lg:text-left  text-center' activeClassName='active rounded-xl p-1' exact> Linux Baby! </NavLink>
                    </li>

                </ul>

            </nav>

        </div>
    )
}
