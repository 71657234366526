import betaLogo from "../assets/BetaLogoNoBG.png";
import me from "../assets/Elephant.PNG"
import sbgLogo from "../assets/SBGGLogo.png";
import gymoLogo from "../assets/GymoLogo.png";
import mLogo from "../assets/mLogo.png";
import GitHubCalendar from "react-github-calendar";
import dayzTill from "../assets/dayzTillLogo.jpg"
import "../styles/homepage.css"
import {useEffect, useState} from "react";
import axios from "axios";
import {recordVisit} from "../NetworkUtility";

export function HomePage2() {
    const [ipAddr, setIP] = useState('')

    useEffect(() => {
        recordVisit("HomePage")
    }, []);



    return (
        <div className='font-bold homeBg rounded-b-xl shadow-inner shadow-black'>


            {/*Main Title Container*/}
            <div className='flex justify-center  p-10'>

                <div className='hidden sm:block'>
                    <img src={betaLogo} alt="App logo for Darts Keeper" width='200' height='390' className='rounded-full'/>
                </div>

                <div className='flex justify-center items-center'>
                    <h1 className='text-5xl text-center md:text-6xl font-bold text-white'>Welcome to My Spot on The Web 🕷</h1>
                </div>

                <div className='hidden sm:block'>
                    <img src={dayzTill} height='390' width='200' className='rounded-full'
                         alt="Baculator App Icon - A drunk duck wearing a hat holding some wine and wine glasses."/>
                </div>

            </div>


            <div className='flex flex-col justify-start  mt-10 ml-2 sm:ml-10'>
                {/*<div className='bg-gray-500 p-10 rounded-2xl'>*/}
                <div className=''>
                    <h1 className='text-white font-bold text-6xl mt-5 p-3'> Hello, World! </h1>
                    <p className="text-white text-2xl text-center sm:text-left p-3">
                        This is my content hub! The place where I host all my content in hopes that you find it
                        interesting and useful! You will find tutorials, guides, and mini courses, as well as product
                        pages and beta links for my public projects.
                    </p>
                </div>

            </div>

            <div className='flex justify-center'>

                <div className='flex justify-center md:gap-10 gap-5 p-2 flex-col md:flex-row'>
                    <div className='std-shadow flex justify-center items-center bg-gradient-to-r from-violet-500 to-green-500 h-16 w-48 rounded-xl'>
                        <p className='text-white text-xl font-bold'>
                            <a href="/ltc" className='text-white '>Learn to Code!</a>
                        </p>

                    </div>

                    <div className='std-shadow flex justify-center items-center  bg-gradient-to-r from-violet-500 to-gray-800  h-16 w-48 rounded-xl '>
                        <p className='text-white text-xl font-bold'>
                            <a href="/studio" className='text-white '>My Projects</a>
                        </p>

                    </div>

                    <div className='std-shadow flex justify-center items-center  bg-gradient-to-r from-blue-500 via-red-500 to-yellow-500  h-16 w-48 rounded-xl '>
                        <p className='text-white text-xl font-bold'>
                            <a href="http://connect4l.s3-website.us-east-2.amazonaws.com" target='_blank' rel="noreferrer" className='text-white '>Play Connect 4</a>
                        </p>

                    </div>
                </div>
            </div>


            <h1 className='text-white font-bold text-6xl text-center my-10 '> Who Am I? </h1>
            <div className='flex justify-center'>

                <div className='flex flex-col lg:flex-row justify-center sm:gap-20'>

                    <div className='text-center text-white text-2xl'>
                        <p className="p-3 ">
                            My name is Cory Sebastian, I am a 28 year old software developer. I develop iOS apps, full
                            stack web apps, command line tools, automation suites, simple games, and more!
                        </p>
                        <p className="p-3 ">
                            I work professionally in automation, and consider myself an indie iOS developer.
                        </p>
                        <p className="p-3 ">
                            I graduated from The University of Minnesota, BUR.
                        </p>
                        <p className="p-3 ">
                            In my free time I train Jiu Jitsu, Muay Thai, and MMA.
                        </p>
                        <div className="flex justify-around pt-5">
                            <img src={sbgLogo} alt="sbg gorilla" height="100" width="100"/>
                            <img src={gymoLogo} alt="sbg gorilla" height="100" width="100"/>
                            <img src={mLogo} alt="sbg gorilla" height="90" width="120"/>
                        </div>
                    </div>

                    <div className='flex justify-center w-full p-10 '>
                        <div className=''>
                            <img src={me} height='300' width="500" className='rounded-full shadow-inner shadow-black p-5' alt="Me"/>
                        </div>
                    </div>

                    <div className='text-white text-2xl text-center'>
                        <p className="p-3">
                            My tech skills have been built through a combination of self teaching and university
                            education. I want to help people who are trying to learn how to develop useful, fun,
                            innovative software.
                        </p>
                        <p className="p-3">
                            Learning how to develop software can be a difficult journey because there is so many
                            different languages / frameworks / OSs / buzz words etc.
                        </p>
                        <p className="p-3">
                            One of the goals of this site is to provide resources / tutorials that I feel would have
                            helped me early on in my technology learning journey.
                        </p>
                        <p className="p-3">
                            I hope you enjoy! And please share any feedback :)
                        </p>

                    </div>
                </div>

            </div>

            <div className='flex justify-center mt-5 sm:p-0 p-5'>
                <div className='flex justify-center bg-gradient-to-br from-purple-700   sm:w-2/4 p-5 rounded-2xl shadow-inner shadow-black'>
                    <a href="https://github.com/corith" className='text-white'>
                        <GitHubCalendar color='violet' username='corith' hideTotalCount='true' hideColorLegend={true}/>
                    </a>
                </div>

            </div>

            {/*BELOW ABOUT ME*/}
            <div className='flex flex-col sm:grid sm:grid-cols-3 sm:grid-rows-2 p-10 gap-10 '>


                <div className='bg-gradient-to-tr from-purple-500 to-purple-900 rounded-2xl h-96 col-start-1 col-end-3 mb-10 shadow-inner shadow-black'>
                    <div className='p-4 font-bold text-white text-xl '>
                        <h1 className='text-white font-bold text-3xl'>Main Languages & Tech</h1>
                        <ul className='list-disc p-5'>
                            <li>
                                Java / Spring
                            </li>
                            <li>
                                Maven
                            </li>
                            <li>
                                Swift / SwiftUI & UIKit
                            </li>
                            <li>
                                JavaScript, React, NodeJs, CSS, Tailwind
                            </li>
                            <li>
                                Linux
                            </li>
                            <li>
                                Python
                            </li>
                            <li>
                                Postgres
                            </li>
                            <li>
                                AWS
                            </li>
                        </ul>
                    </div>
                </div>


                <div className='std-shadow bg-purple-700 rounded-2xl h-96 col-start- col-end-4 shadow-inner shadow-black'>
                    <div className='p-4 font-bold text-white text-xl'>
                        <h1 className='text-white font-bold text-2xl md:text-3xl'>Experience With</h1>
                        <ul className='list-disc p-5'>
                            <li>
                                C/C++
                            </li>
                            <li>
                                Verilog
                            </li>
                            <li>
                                Assembly
                            </li>
                            <li>
                                Angular, Ionic, etc.
                            </li>
                            <li>
                                PHP
                            </li>
                        </ul>
                    </div>
                </div>


            </div>

        </div>
    )
}

