import '../../styles/learn-to-code.css'

import {SideBar} from "../../components/SideBar";
import {recordVisit} from "../../NetworkUtility";
import {useEffect} from "react";

export function LearnToCode() {

    useEffect(() => {
        recordVisit("LearnToCode")
    }, []);

    return (
        <div className=''>


            <div className='grid lg:grid-rows-3cl lg:grid-cols-2ltc gap-6 p-6 backGroundLTC rounded-b-xl shadow-inner shadow-black'>

                <SideBar />

                <div className='lg:col-start-2 lg:col-end-3 rounded-full'>
                    {/*<h1 className='text-center text-7xl font-bold text-white gradBG std-shadow rounded-full p-3'> So You Wanna Code, Huh? </h1>*/}
                    <h1 className='text-center p-5 text-7xl font-bold text-transparent bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text'> So,
                        You Wanna Code, Huh? </h1>
                </div>


                <div className=' rounded-2xl lg:row-start-2 lg:row-end-4 pb-2'>

                    <div className=''>

                        <div className='flex justify-center'>
                            <div className="  rounded-xl  h-full  text-white   font-bold">
                                <h1 className='text-center text-6xl  mb-12'> How to Get Started </h1>
                                <p className='text-3xl'>
                                    How to start learning coding & software development can certainly be daunting!
                                </p>
                                <p className='text-3xl py-10'>
                                    There are lots of buzzwords, opinions, and technologies are spawning and changing
                                    everyday, making some resources outdated.

                                </p>
                                <p className='text-3xl pb-10'>
                                    No need to be intimidated, there are lots of up to date resources and tools to help you along the way, so
                                    let's discuss how to get started!
                                </p>
                                <p className='text-3xl  text-center text-pink-400'>
                                    For the most part, it doesn't really matter where you start, just that you start!
                                </p>
                                <p className='text-3xl  text-center py-10 text-pink-300'>
                                    If you are brand new I suggest starting as basic as possible.
                                </p>
                                <p className='text-3xl pt-5'>
                                    An important first step when starting, is to think about why you want to get
                                    started. Why do you want to learn how to develop software?
                                </p>
                                <p className='text-3xl pt-5'>
                                    This could be to get a job, to create something personally useful, just to pick up a
                                    new hobby, etc.
                                </p>
                                <p className='text-3xl pt-5'>
                                    It is crucial to know your reason for practicing.
                                    This will and should, impact the projects you use to practice, your first language,
                                    what kind of algorithms and data structures you might want to study, and more.
                                </p>
                                <p className='text-3xl pt-5 text-gray-200'>
                                    Once you've got your reason for learning software development / how to code, next
                                    step would probably be to decide on what language you are going to start with. You
                                    can check out our belief on <a
                                    href="/ltc/first">choosing a first language</a>!
                                </p>

                                <p className='text-3xl pt-5'>
                                    Finally, GET STARTED!! Start with all the cliche starter projects like, Hello,
                                    World. Make a weather converter. Create a Todo app, etc.
                                </p>
                                <p className='text-3xl pt-5'>
                                    These projects help introduce you to writing code, compiling code, version control,
                                    and problem solving in a manner that is most likely unique / new to most.
                                    Check out our <a href="/ltc/start">starter projects</a> to help get started!
                                </p>
                                <p className='text-3xl pt-5'>
                                    Try to commit as much time as your schedule allows. At the bare minimum try to plan
                                    out at least 30 minutes a day for development related actives, with the main focus
                                    being on actually writing and executing code (as opposed to reading / watching
                                    tutorials). This rule can be easily cheated, but maybe aim for a couple commits a
                                    day!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
