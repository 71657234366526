import {SideBar} from "../../components/SideBar";

export function Tutorials() {

    return (
        <div className=''>


            <div className='grid lg:grid-rows-3cl lg:grid-cols-2ltc gap-6 p-6 backGroundLTC rounded-b-xl pb-10 mb-2 shadow-inner shadow-black'>

                    <SideBar />

                    <div className='lg:col-start-2 lg:col-end-3 '>
                        {/*<h1 className='text-center p-5 text-3xl text-white std-shadow rounded-full'> Tutorials Coming Soon! </h1>*/}
                        <h1 className='text-center pt-5 text-6xl sm:text-7xl font-bold text-transparent bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text'> Tutorials </h1>

                        <p className='text-center p-5 text-base text-white'><span className="text-purple-400 text-2xl">Content currently in progress! Check back soon :)</span><br/></p>
                        <p className='text-center text-white'>
                            For now, enjoy these videos that I found useful.
                        </p>
                    </div>

                    <div className='grid lg:grid-cols-2 gap-6 lg:row-start-2 lg:row-end-4 '>
                        <div className=''>
                            <iframe title='revolution-os' height="315" className='rounded-2xl std-shadow w-full'  src="https://www.youtube.com/embed/w7ejDZ8SWv8" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                        </div>
                        <div className=''>
                            <iframe title='revolution-os' height="315" className='rounded-2xl std-shadow w-full'  src="https://www.youtube.com/embed/hdI2bqOjy3c" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                        </div>

                        <div className=''>
                            <iframe  title='linux-better' height="315" src="https://www.youtube.com/embed/JJmcL1N2KQs" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen className='rounded-2xl std-shadow w-full'/>
                        </div>
                        <div className=''>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/u8UOmfYmpoE"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen className='rounded-2xl std-shadow w-full'/>
                        </div>
                        <div className=''>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/U7itlR6qESM"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen className='rounded-2xl std-shadow w-full'/>
                        </div>
                        <div className=''>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/m_MQYyJpIjg"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen className='rounded-2xl std-shadow w-full'/>
                        </div>




                    </div>
                </div>

        </div>
    )
}
