import {SideBar} from "../../components/SideBar";
import "../../styles/learn-to-code.css"
import javaLogo from "../../assets/java-ball.png"
import cLogo from "../../assets/C-ball.png"
import pyLogo from "../../assets/python-ball.png"
import closedBall from "../../assets/closed_pokeball.png"
import webBall from "../../assets/webdev-ball.png"
import {useEffect, useState} from "react";
import {recordVisit} from "../../NetworkUtility";

export function ChooseLanguage() {
    const [javaClosed, setJclose] = useState(true)
    const [pyClosed, setPyclose] = useState(true)
    const [cClosed, setCclose] = useState(true)
    const [webDevClosed, setWebclose] = useState(true)


    useEffect(() => {
        recordVisit("ChooseLang")
    }, []);

    return (
        <div className='backGroundLTC rounded-b-xl font-bold shadow-inner shadow-black'>
            <div className='lg:grid lg:grid-rows-3cl lg:grid-cols-2ltc flex-col flex gap-6 p-6 pb-10'>

                <SideBar />

                <div className='lg:col-start-2 lg:col-end-3 rounded-full '>
                    <h1 className='text-center p-5 text-6xl sm:text-7xl font-bold text-transparent bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text'> Choosing Your First Language </h1>
                </div>

                <div className=' rounded-2xl lg:row-start-2 lg:row-end-4'>

                    {/*<div className="lg:text-5xl flex justify-center text-white font-bold text-2xl pt-10">*/}
                    {/*    NEW & IMPROVED CONTENT IN THE WORKS 🛠️*/}
                    {/*</div>*/}

                    <div className='text-white'>


                        <div className='flex justify-center'>
                            <div className='text-3xl'>

                                 {/*<h1 className='flex justify-center text-7xl pb-10'> Picking your first language! </h1>*/}
                                <p className='pb-5'>
                                    Picking your first language can feel like choosing your first Pokemon, and although you wan't a good Pokemon to start, no matter which one you choose first, you still gotta catch them all!
                                </p>

                                <p className='py-5'>
                                    For the vast majority of you, the first language doesn't really matter. The only real exception is if your reasons for learning is related to a specific domain / company that uses language X.
                                    Then, you should probably start with language X.
                                </p>

                                <p className='py-5'>
                                    It is important to keep in mind that most developers end up learning more than one language. I once heard, "learning a new programming language should only take a weekend". Obviously, this is an exaggeration and
                                    certainly something that seemed impossible when I was starting out! But, it makes sense because once you've learned one programming language, you see that for the most part they are all built using common principles.
                                    A new language then becomes significantly easier to pick up and use.
                                </p>
                                <p className='py-5'>
                                    So although you probably aren't going to be an authority in the new language after 2 days, if you learn programming concepts, and the things that make all programming languages similar, loops, variables, etc., you will be amazed at how quickly you become useful in a new language.

                                </p>
                                <p className='py-5'>
                                    Man, just like Pokemon, people have lots of opinions about which language is the best, which is best for beginners, blah blah blah. Although there are probably some that are simpler than others to start with, almost all of these opinions boil down to preference.
                                    It is fine to have your favorite, but be weary of getting envolved in the language holy wars.
                                </p>
                                <p className='py-5 text-center'>
                                    So hopefully you see that the main thing you need to do is: PICK ONE!!
                                </p>

                                <p className='py-5'>

                                    Below, we will give you some of our perspectives on the most commonly suggested first languages. We will discuss some things to keep in mind when deciding on a language or dev tool.
                                </p>

                                <p className='py-5'>
                                    This may sway you in one direction or the other but, if everyone's two cents is overwhelming / confusing, truly just randomly pick any one of the top 5 most used languages and get started!!
                                </p>
                            </div>
                        </div>







                        <h3 className='text-5xl sm:text-7xl py-20 flex justify-center text-center bg-gradient-to-r from-red-500 to-gray-200 bg-clip-text text-transparent'> Potential First Languages</h3>
                        <div className='flex sm:flex-row sm:justify-center gap-10 flex-col items-center'>

                            <div className='p-5 rounded-2xl gradBG std-shadow'>
                                <div className='text-2xl'>
                                    <p className='flex justify-center pt-5  text-3xl  font-bold pb-5'>
                                        {javaClosed ? 'Click to Reveal' : 'Java'}
                                    </p>
                                    <div className='flex justify-center'>
                                        <button>
                                            <img src={javaClosed ? closedBall : javaLogo} alt="java logo"  className='' onClick={() => openBallFor("java")}/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='p-5 rounded-2xl gradBG std-shadow'>
                                <div className=' text-2xl '>
                                    <p className='flex justify-center pt-5 text-3xl  font-bold pb-5'>
                                        {pyClosed ? 'Click to Reveal' : 'Python'}
                                    </p>
                                    <div className='flex justify-center'>
                                        <button>
                                            <img src={pyClosed ? closedBall : pyLogo} alt="python logo" className='rounded-full'  onClick={() => openBallFor("python")}/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='p-5 rounded-2xl gradBG std-shadow'>
                                <div className=' text-2xl '>
                                    <p className='flex justify-center pt-5 text-3xl   font-bold pb-5'>
                                        {webDevClosed ? 'Click to Reveal' : 'Web Dev'}
                                    </p>
                                    <div className='flex justify-center'>
                                        <button>
                                            <img src={webDevClosed ? closedBall : webBall} alt="web dev logo" className='' onClick={() => openBallFor("web")}/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='p-5 rounded-2xl gradBG std-shadow'>
                                <div className=' text-2xl '>
                                    <p className='flex justify-center pt-5 text-3xl   font-bold pb-5'>
                                        {cClosed ? 'Click to Reveal' : 'C'}
                                    </p>
                                    <div className='flex justify-center'>
                                        <button>
                                            <img src={cClosed ? closedBall : cLogo} alt="web dev logo" className='' onClick={() => openBallFor("c")}/>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div>

                            {
                                pyClosed ? <div></div> :

                                    <div className='flex justify-center '>
                                        <div className='std-shadow rounded-xl lg:h-4/5 h-full  text-white sm:w-11/12 p-5 mt-10 bg-gray-700 text-3xl'>
                                            <h1 className='flex justify-center text-6xl py-8 font-black text-blue-400'> Pyt <span className='text-yellow-400'>hon</span> </h1>

                                            <p className='py-5'>
                                                Python is frequently recommended for beginners and is used in several professions ranging from data science, Ai, scripting, backend, games, etc.
                                            </p>
                                            <p className='py-5'>

                                                One of the things that makes it nice for someone just starting out is that you can get up and running relatively easy. All you need is the python SDK, a .py file and you should be good to go. Some languages require a little more syntax than others to get started. This requires a certain level of "acceptance" by the learner, which can be confusing and difficult. With python, one only needs to create a .py file in their favorite editor in order to start playing around.
                                            </p>

                                            <p className='py-5'>
                                                The community is large so finding solutions, resources, and useful libraries comes easy. You should have no problem finding many implementations of common "beginner" applications. The python documentation is pretty solid and can be helpful once you have learned how to read documentation in general.

                                            </p>
                                            <p className='py-5'>
                                                Python has support for several different coding paradigms, from functional coding to object oriented (OOP). Because python uses something called an interpreter to execute your code, this makes running it on different machines pretty hassle free.


                                            </p>
                                            <p className='py-5'>
                                                This is a language that you will most likely learn and work with at some point even if it is not the one you start with, nor the language you come to use most often.
                                            </p>
                                            <p className='py-5'>
                                                You might see that "python is slow". This is something that does not impact most people who use python. As you become more experienced, you will understand what is meant by slow, and when that might be an issue for you. For now, this is one of those things that you shouldn't get too wrapped up in. I promise "hello, world" is going to execute at the same speed for all languages.
                                            </p>
                                            <p className='py-5 text-center'>
                                                You can download Python <a href="https://www.python.org/downloads/">here</a>! And you can read the docs <a
                                                href="https://docs.python.org/3/">here</a>!
                                            </p>
                                            <p className='py-5 text-center'>
                                                Some machines may already have a python version installed.
                                            </p>
                                        </div>

                                    </div>

                            }


                            {
                                javaClosed ? <div/> :

                                    <div className='flex justify-center'>
                                        <div className='std-shadow rounded-xl lg:h-4/5 h-full sm:w-11/12 text-white p-5 mt-10 bg-gray-700 text-3xl'>
                                            <h1 className='flex justify-center text-6xl py-8 font-black text-red-500'> Java </h1>

                                            <p className='py-5'>
                                                 Java is a widely used language that has been around for awhile. This language requires a little more overhead before getting a successful "Hello, World." program to print. Nonetheless, this language is very capable, well built out, and has a large community for support.
                                             </p>

                                            <p className='py-5'>
                                                This language is widely used on the server and for creating desktop applications. It is used in android development. Wide array of libraries to do just about anything you need. Execute its code using the Java Virtual Machine. This allows for your Java code to run on any machine that has the JVM installed.
                                            </p>
                                            <p className='py-5'>
                                                Java is an Object Orient language which means that it has support for Classes, Inheritance, Abstraction, Encapsulation, etc. This language has good standard libraries as well as 3rd party ones. They make it easy for developers to provide documentation for their code with JavaDocs.
                                            </p>
                                            <p className='py-5'>
                                                This language is used very heavly on backend servers within the financial industry, e-commerce, automation, developer tools, etc.
                                            </p>
                                            <p className='py-5'>
                                                Since Java is such a strong OOP (object oriented programming) language, it can be harder to get started with than say Python. Python is an OOP language but does not require it the same way Java does. Basically, Python does not require your code to be in a class for it to execute. Since Java only really allows OOP, everything in Java must be in a class. That means before you print "Hello, World" you must create a class with a "main" method. To understand all that, it requires also learning a bit of the basics around OOP.
                                            </p>
                                            <p className='py-5'>
                                                Don't let that scare you though! Object Oriented Programing is one of the most popular programming paradigms out there. You will most certainly want to learn it and learn it well.
                                            </p>
                                            <p className='py-1 text-center mb-20'>
                                                We will discuss OOP in further detail in our <a href="/ltc/tutorials">tutorial series</a>!
                                            </p>
                                            <p className='py-1 text-center'>
                                                For a general Java reference checkout <a
                                                href="https://www.programiz.com/java-programming">Programiz</a>.
                                            </p>
                                            <p className='py-1 text-center'>
                                                For a quick reference on OOP, check out this <a href="https://en.wikipedia.org/wiki/Object-oriented_programming" target='_blank' rel="noreferrer">Wiki</a>.
                                            </p>
                                            <p className='py-1 text-center'>
                                                For a comparison between OOP in Java & Python <a
                                                href="https://realpython.com/oop-in-python-vs-java/">Click Here</a>.
                                            </p>

                                            <div className='bg-gray-900 rounded-xl p-8 mt-5'>
                                                <p className='py-1 text-center'>
                                                    You can download the Oracle JDK <a href="https://www.oracle.com/java/technologies/downloads/" target='_blank' rel="noreferrer">here</a>.
                                                </p>
                                                <p className='py-1 text-center'>
                                                    You can download the openJDK <a href="https://jdk.java.net/19/" target='_blank' rel="noreferrer">here</a>.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                            }


                            {
                                webDevClosed ? <div></div> :

                                <div className='flex justify-center'>
                                    <div className= 'std-shadow rounded-xl lg:h-4/5 h-full text-white sm:w-11/12 p-5 mt-10 bg-gray-700 text-xl'>

                                        <h1 className='flex justify-center text-4xl sm:text-6xl py-8 font-black text-transparent bg-clip-text  bg-gradient-to-r from-red-500 via-blue-400 to-yellow-500'>Web Development</h1>

                                        <div className='text-3xl'>
                                            <p className='py-5'>
                                                Frontend Web development can be a good place to start because you can get perhaps a slightly more rewarding experience by seeing your code updates graphically. This is in contrast to a lot of other languages whose starter projects usually entail some sort of text based user interface.
                                            </p>
                                            <p className='py-5'>
                                                To learn web development, it isn't just one language but sorta 3 that work together. Don't panic, really only 1 of these is an actual programming language.
                                            </p>
                                            <p className='py-5'>
                                                These 3 languages are, HTML, CSS, and Javascript. With HTML being a markup language, CSS being a styling language, and JavaScript being a programming language.
                                            </p>
                                            <p className='py-5'>
                                                JavaScript has NOTHING to do with Java. Make sure you understand that.
                                            </p>
                                            <p className='py-5'>
                                                JavaScript can be a slightly tricky language in general, which can make it slightly less welcoming to beginners.
                                            </p>
                                            <p className='py-5'>
                                                Modern Web Development relies heavily on frameworks like, Angular, ReactJS, Next.js, TailwindCSS, etc. and tools like Node, npm, etc.
                                            </p>
                                            <p className='py-5'>
                                                These frameworks and tools make for a very powerful development tool box. However, they all require their own learning curve, and can make it hard to conceptualize what is actually going on as a beginner.
                                            </p>
                                            <p className='py-5'>
                                                Most developers know something about web development. They could at least build themselves an ugly website. There are plenty of resources online for learning it.
                                                A big part of learning web development is finding what functionalities / components you commonly want to create. Building your first couple websites will require you to
                                                google everything. "How to make a paragraph in HTML". "How to style a paragraph using CSS". "How to ask a user for their name JavaScript". The ones you google more often are the one's you'll remember.
                                                The rest, google isn't going anywhere!
                                            </p>
                                            <p className='py-5 text-2xl'>
                                                <span className='text-red-500 text-3xl'>OPINION ALERT:</span> I think it is more "transferable" to learn one of the other languages first like, Java, Python, Swift, Go, C, Ruby, etc. and then go to JavaScript / web dev.
                                                JavaScript is a unique to the above mentioned languages and therefore I think it is just smoother to transfer experiences from those languages to JS as opposed to the other way around.
                                            </p>
                                            <p className='py-5 text-center'>
                                                <a href="https://developer.mozilla.org/en-US/docs/Learn/Getting_started_with_the_web" target='_blank' rel="noreferrer">Mozilla</a> has some great resources for getting started with web development
                                            </p>
                                            <p className='py-5 text-center'>
                                                We're sllightly biased towards ReactJS, but check out <a href="https://reactjs.org/docs/getting-started.html" target='_blank' rel="noreferrer">ReactJS</a> or <a
                                                href="https://angular.io/docs" target='_blank' rel="noreferrer">Angular</a>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            }


                            {
                                cClosed ? <div></div> :
                                    <div className='flex justify-center'>
                                        <div className='std-shadow rounded-xl lg:h-4/5 h-full  text-white sm:w-11/12 p-5 mt-10 bg-gray-700 text-3xl'>
                                            <h1 className='flex justify-center text-indigo-400 text-center text-4xl sm:text-6xl py-8 font-black'>The C Programming Language </h1>
                                            <p className='py-5'>
                                                C is the only real programming language...
                                            </p>
                                            <p className='py-5'>
                                                Just kidding of course. C is probably not the best language to start with, but certainly deserves a mention.
                                            </p>

                                            <p className='py-5'>
                                                C has a lot of history behind it as one of the first high level programming languages. It is still used today, but its use has been declining over the years.
                                                The main places you will see C code in the wild is in operating systems, embedded systems, and <a href="https://www.pidgin.im">pidgin 😉</a>.
                                            </p>
                                            <p className='py-5'>
                                                A lot of the popular high level languages today were derived or some part inspired by the C programming language. You may even hear the C language is the mother
                                                of all languages. For example Java, Objective-C, C++, JavaScript, Python, PHP, and many other languages all take from C in some capacity.
                                            </p>
                                            <p className='py-5'>
                                                C is an older language, and what makes it powerful and desirable for those that use it, makes it undesirable for most newcommers.
                                            </p>
                                            <p className='py-5'>
                                                In C memeroy is the responsibility of the programmer. This can be very powerful when used properly, but can cause problems and add unnecessary complexity for a beginner.
                                            </p>
                                            <p className='py-5'>
                                                Newer high level languages like Java, Python, Swift, etc.,  manage memory for you by default. They use  garbage collection and auto reference counting to know when an object is no longer needed.
                                                They save manual memory handling only for those who need / want it. These languages tend to be a more hospitable environment for beginners as well as experienced developers.
                                            </p>
                                            <p className='py-5'>
                                                With that said, C is a good language to study and explore because so many concepts of other languages solve "problems", "abstract", and "improve" on functionality from C. Learning C and then moving
                                                on to other languages is certainly a useful path. Just one that may entail more friction than starting with a more "modern" language.
                                            </p>
                                            <p className='py-5'>
                                                For some good resources on C check out <a href="https://ict.iitk.ac.in/c-the-mother-of-all-languages/">Mother of All Languages</a>, <a href="https://www.programiz.com/c-programming" target='_blank' rel="noreferrer">Programiz</a>, and <a
                                                href="https://www.britannica.com/technology/C-computer-programming-language" target='_blank' rel="noreferrer">Britannica: C</a>
                                            </p>

                                        </div>

                                    </div>
                            }



                            <p className='text-center pt-5 text-xl'>
                                Once you have picked your language, head on over to the <a href="/ltc/start"> <span className=''>Starter projects </span> </a> section to get started using that language!
                            </p>
                        </div>


                        <div>
                            <p className='p-10 text-center'>
                                The above recommendations are aimed at giving you a language to start with that will allow you to develop an understanding of programming and software development in general.
                                Having a general understanding of one of the above mentioned languages, along with core concepts of coding, will allow you pick up/learn other languages more easily. With that said,
                                remember, just because a language was not listed above, DOES NOT mean that it is a bad language to start with.

                            </p>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    )
    function openBallFor(pokeBall) {
        if ( pokeBall === "java" ) {
            setPyclose(true)
            setCclose(true)
            setWebclose(true)
            setJclose(!javaClosed)
        } else if ( pokeBall === "python" ) {
            setJclose(true)
            setCclose(true)
            setWebclose(true)
            setPyclose(!pyClosed)
        } else if ( pokeBall === "c" ) {
            setJclose(true)
            setPyclose(true)
            setWebclose(true)
            setCclose(!cClosed)
        } else if (pokeBall === "web") {
            setJclose(true)
            setPyclose(true)
            setCclose(true)
            setWebclose(!webDevClosed)
        }
    }
}
