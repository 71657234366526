import "../styles/watcha-think.css"
import fifaCover from "../assets/fifaCover.png"
import ghost from "../assets/ghost.png"
import whiteBelt from "../assets/whiteBelt.png"

export const WatchaThink = () => {

    return(
            <div className='bg-gray-800 rounded-b-xl'>

                <div className='flex justify-center p-10'>
                    <h1 className="text-6xl text-white flex justify-center text-center font-bold ">Watcha Think About That??</h1>
                </div>




                {/*Genre section*/}

                {
                        <div className='flex justify-center'>

                            <div className='flex flex-col justify-center gap-7 md:flex-row'>

                                <div className="w-32 xl:w-60 h-20 bg-green-500 rounded-xl std-shadow">

                                   <p className='flex justify-center text-white text-xl pt-3 font-bold'>
                                       Tech
                                   </p>
                                    <p className='flex justify-center text-white text-3xl'>
                                        📱📟👩🏼‍🔬
                                    </p>
                                </div>
                                <div className="w-32 xl:w-60 h-20 bg-black rounded-xl std-shadow">
                                    <p className='flex justify-center text-white text-xl font-bold'>
                                        Jits / MMA
                                    </p>
                                    <img src={whiteBelt} alt="" className=''/>
                                </div>
                                <div className="w-32 xl:w-60 h-20 bg-red-500 rounded-xl std-shadow">
                                    <p className='flex justify-center text-white text-xl pt-3 font-bold'>
                                        Art & Media
                                    </p>
                                    <p className='flex justify-center text-white text-3xl'>
                                        🎨🎭🖼
                                    </p>
                                </div>
                            </div>
                        </div>
                }



                {/*Featured section*/}

                <div className='flex justify-center pt-10 sm:pt-20'>

                    <div className='grid grid-cols-1 md:grid-cols-2 sm:grid-rows-1 gap-20  md:gap-20'>

                        <div className='sm:w-96 sm:h-96 w-80 h-80 std-shadow rounded-xl bg-gradient-to-r from-purple-300 via-purple-700 to-purple-300'>
                            <h1 className='text-2xl text-white flex justify-center pt-3'> Fifa 23 </h1>
                            <p className='flex justify-center text-purple-200 text-lg'>Coming up on a month here's what I think.</p>
                            <img src={fifaCover} alt="Mbappe and the lady from fifa 23" height="400" width="400"/>
                        </div>

                        <div className='sm:w-96 sm:h-96 w-80 h-80 std-shadow rounded-xl bg-gradient-to-l from-purple-300 via-purple-700 to-purple-300'>
                            <h1 className='text-2xl text-yellow-500 flex justify-center pt-3'> Happy Halloween! </h1>
                            <p className='flex justify-center text-purple-200 text-lg'>What's up with Halloween hauntings??</p>
                            <img src={ghost} alt="Mbappe and the lady from fifa 23" height="400" width="400"/>
                        </div>
                    </div>
                </div>


                {/*5 most recent*/}


                <div className='flex justify-center mt-10'>
                    <div className='w-4/5 sm:w-3/5'>
                        <div>
                            <ul className=''>
                                <li className='mb-5'>
                                    <div className='rounded-xl fiveList std-shadow bg-gradient-to-r from-purple-700 via-purple-400 to-purple-700'>
                                        <h1 className='text-white text-2xl flex justify-center p-5'> Why Microsoft Windows is so tough to use? </h1>
                                    </div>
                                </li>

                                <li className='mb-5'>
                                    <div className='rounded-xl fiveList std-shadow bg-gradient-to-r from-purple-700 via-purple-400 to-purple-700'>
                                        <h1 className='text-white text-2xl flex justify-center p-5'> Ice cream tastes better when its small </h1>
                                    </div>
                                </li>

                                <li className='mb-5'>
                                    <div className='rounded-xl fiveList std-shadow bg-gradient-to-r from-purple-700 via-purple-400 to-purple-700'>
                                        <h1 className='text-white text-2xl flex justify-center p-5'> Is Jiu Jitsu the best martial art? </h1>
                                    </div>
                                </li>

                                <li className='mb-5'>
                                    <div className='rounded-xl fiveList std-shadow bg-gradient-to-r from-purple-700 via-purple-400 to-purple-700'>
                                        <h1 className='text-white text-2xl flex justify-center p-5'> What a wild world we live in... </h1>
                                    </div>
                                </li>

                                <li className='mb-5'>
                                    <div className='rounded-xl fiveList std-shadow bg-gradient-to-r from-purple-700 via-purple-400 to-purple-700'>
                                        <h1 className='text-white text-2xl flex justify-center p-5'> Why living in the Apple ecosystem feels so nice. </h1>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*<div className='h-screen'>*/}

                    {/*</div>*/}
                </div>
            </div>
    )
}