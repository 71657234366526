import {recordVisit} from "../NetworkUtility";
import {useEffect} from "react";

export const PrivacyPolicy = () => {

    useEffect(() => {
        recordVisit("Privacy")
    }, []);

    return (
        <div className="bg-black">
            <h1 className='sm:text-8xl text-5xl flex justify-center text-white p-7'> Privacy Policy </h1>

            <div className='flex justify-center md:p-20 p-5'>

                <div className='bg-gray-800 rounded-xl shadow-inner shadow-black'>
                <div className='p-10 text-white text-2xl'>
                    <h1 className='text-3xl font-bold flex justify-center pb-5 text-center'>
                        Encompassed Applications
                    </h1>
                    <div className='flex justify-center shadow-inner shadow-black rounded-xl p-6'>
                        <ul className='text-gray-400 text-center'>
                            <li className="py-3">
                                Activity Tiles (iOS)
                            </li>
                            <li className="py-3">
                                Darts Keeper (iOS)
                            </li>
                            <li className="py-3">
                                corith.com (Web)
                            </li>
                            <li className="py-3">
                                Baculator Pro (iOS)
                            </li>
                            <li className="py-3">
                                Dayz Till (iOS)
                            </li>
                            <li className="py-3">
                                ARPT (iOS)
                            </li>
                            <li className='pt-10'>
                                All projects under my name that claim this policy as their privacy policy.
                            </li>
                        </ul>
                    </div>
                </div>

                </div>

            </div>


            <div className='sm:flex grid gap-5  sm:p-15 p-5 pb-20'>

                <div className='flex-col sm:flex  gap-5'>
                    <div className='pb-5 sm:pb-0'>
                        <div className='bg-gray-800 p-8 rounded-3xl shadow-inner shadow-black'>
                            <div>
                                <h3 className="text-2xl text-purple-400">The Policy</h3>
                                <p className='font-bold text-white text-lg'>
                                    We do not collect any information at this time.
                                </p>
                            </div>
                        </div>


                    </div>
                    


                    <div className='pb-5 sm:pb-0'>
                        <div className='bg-gray-800 p-8 rounded-3xl shadow-inner shadow-black'>
                            <div>
                                <h3 className="text-2xl text-blue-400">My Promise</h3>
                                <p className='font-bold text-white text-lg'>
                                    I promise to never use your data as the main driving force of any business venture.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='bg-gray-800 p-8 rounded-3xl shadow-inner shadow-black'>
                            <div>
                                <h3 className="text-2xl text-yellow-400">Security</h3>
                                <p className='font-bold text-white text-lg'>
                                    I take security seriously when developing my apps both personally and professionally.
                                </p>
                                <p className='font-bold text-white text-lg pt-5'>
                                    When it comes to security, there is only so much everyone can do.
                                </p>
                                <p className='font-bold text-white text-lg pt-5'>
                                    I am not responsible for stolen data.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='flex'>
                    <div className='bg-gray-800 p-10 rounded-3xl shadow-inner shadow-black '>
                        <div>
                            <h3 className="text-2xl text-green-400">What about tomorrow?</h3>
                            <p className='font-bold text-white text-lg'>
                                Currently a major reason for not collecting ANY information at all is because it is a lot of work & money.
                            </p>
                            <p className='font-bold text-white text-lg pt-7'>
                                Eventually I plan to be able to afford both the time and the money to collect relevant data. I want to do this to help improve MY apps!
                            </p>
                            <p className='font-bold text-white text-lg pt-7'>
                                I will never be interested in storing user data for the purpose of selling that data. My goal is to build fun, cool, exciting apps, not farm user data.
                            </p>
                        </div>
                    </div>
                </div>

            </div>




        </div>
    )
}