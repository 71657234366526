import React from 'react'
import '../styles/project-card.css'
import hacker from '../assets/hacker.webp.png'
import githublogo from '../assets/githublogo.png'
import memoji from '../assets/coryMemoji.png'

export const ProjectCard = ({ projName, projDes, repoUrl, lang, bgleft, i }) => {

    const getLeftBg = (i) => {
        if (i < 3) {
            switch (i % 3) {
                case 0:
                    return "card-left-bg"
                case 1:
                    return "card-left-bg1"
                case 2:
                    return "card-left-bg2"
                default:
            }
        }

        else if (i >= 3 && i < 6) {
            switch (i % 3) {
                case 1:
                    return "card-left-bg"
                case 2:
                    return "card-left-bg1"
                case 0:
                    return "card-left-bg2"
                default:
            }
        } else if (i >=6) {
            switch (i % 3) {
                case 2:
                    return "card-left-bg"
                case 0:
                    return "card-left-bg1"
                case 1:
                    return "card-left-bg2"
                default:
                    return ''
            }
        }


    }

    const getRandomNum = () => {
        return Math.floor(Math.random() * (4-1) + 1)
    }


    const getRandomImg = (i) => {
        if (i < 3) {
            switch (i) {
                case 0:
                    return memoji
                case 1:
                    return githublogo
                case 2:
                    return hacker
                default:

            }

        }

        switch (getRandomNum()) {
            case 0:
                return hacker
            case 1:
                return githublogo
            case 2:
                return memoji
            default:
                return hacker
        }
    }

    return (
        <div className='flex  std-shadow bg-gray-700 rounded-2xl shadow-inner shadow-gray-400'>

            {/*left side*/}
            <div className={getLeftBg(i) +  ' card-left-bg flex text-center flex-col justify-between p-4 rounded-2xl items-center shadow-inner shadow-gray-800'}>
                <h1 className='text-white text-sm font-bold'> { projName } </h1>

                <img src={getRandomImg(i)} width="125px" height="125px" alt="github cat"/>
                <a className='projLink-bg p-2 rounded-full font-bold no-underline' href={repoUrl}> {projName} Repo </a>
            </div>

            {/* right side */}
            <div className='flex flex-col justify-center font-bold p-4 w-2/3 text-white'>
                <p className='mb-auto sm:text-xl'>
                    {projDes}
                </p>

                <div>
                    Language: {lang === null ? "NA" : lang}
                </div>
            </div>

        </div>
    )

}
