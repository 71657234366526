import {SideBar} from "../../components/SideBar";
import construction from "../../assets/purpConstruction.png"

export function LinuxBaby() {

    return (
        <div className='backGroundLTC rounded-b-xl shadow-inner shadow-black'>
            <div className='grid lg:grid-rows-3cl lg:grid-cols-2ltc  gap-6 p-6'>

                <SideBar />

                <div className='lg:col-start-2 lg:col-end-3  '>
                    {/*<h1 className='text-center p-5 text-6xl font-bold text-white'> LINUX BABY!! </h1>*/}
                    <h1 className='text-center pt-5 text-6xl sm:text-7xl font-bold text-transparent bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text'> LINUX BABY! </h1>
                </div>

                <div className='std-shadow rounded-2xl lg:row-start-2 lg:row-end-4 '>

                    <div className='flex justify-around flex-col sm:flex-row'>
                        <img src={construction} height='300' width='500' alt=""/>
                        <div>
                            <h1 className='p-5 text-white text-base text-3xl font-bold'> My Favorite Distros to check out </h1>
                            <ol className='list-decimal ml-10 text-white text-2xl px-5'>
                                <li>
                                    <a href="https://archlinux.org/">Arch Linux</a> (Not as a beginner)
                                </li>
                                <li>
                                    <a href="https://manjaro.org/"> Manjaro </a>
                                </li>
                                <li>
                                    <a href="https://ubuntu.com/"> Ubuntu </a> (Any flavor of Ubuntu is best for first timers in my opinion)
                                </li>
                                <li>
                                    <a href="https://tails.boum.org/"> Tails </a> (Cause who doesn't want to feel like a spy?)
                                </li>
                                <li>
                                    <a href="https://getfedora.org/"> Fedora </a>
                                </li>
                                <li>
                                    <p> Any distro, Debian, Hannah Montana Linux, centOS, etc. &lt;3 </p>
                                </li>
                            </ol>
                        </div>
                    </div>


                    <div className='grid lg:grid-cols-2 gap-5 p-7'>

                        <iframe title='revolution OS' height="315" src="https://www.youtube.com/embed/jw8K460vx1c" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen className='rounded-2xl shadow2 w-full'/>

                        <iframe title='linux-better' height="315"  src="https://www.youtube.com/embed/mAFMJ1LnQu8" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen className='rounded-2xl shadow2 w-full'/>
                    </div>
                </div>
            </div>
        </div>

    )
}
