import {SideBar} from "../../components/SideBar";
import purpDev from "../../assets/purpConstruction.png"

export function WishIknew() {

    return (
        <div className='backGroundLTC pb-20 rounded-b-xl shadow-inner shadow-black'>

            <div className='grid lg:grid-rows-3cl lg:grid-cols-2ltc  gap-6 p-6'>

                <SideBar/>

                <div className='lg:col-start-2 lg:col-end-3  rounded-full  rounded-full'>
                    {/*<h1 className='text-center p-5 text-5xl text-white'> Hindsight 20/20 </h1>*/}
                    <h1 className='text-center p-5 text-6xl sm:text-7xl font-black text-transparent bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text'> Hindsight
                        20/20 </h1>
                </div>

                <div className=' rounded-2xl lg:row-start-2 lg:row-end-4 '>
                    <h3 className='text-center py-5 text-pink-400 text-3xl font-bold'> This is a collection of answers to questions that looking back seem silly.</h3>
                    <h3 className='text-center py-5 text-pink-300 text-3xl font-bold'> It also includes things that I wish I had known earlier along in my journey.</h3>

                    <div className="flex justify-center">


                        {/*<div className='p-5'>*/}
                        {/*    <QnAElement question={"What is a server?"}*/}
                        {/*                answer={"What is a server? The answer may be a little more complicated than it should be. When a person refers to something as the server, it could  be anything." +*/}
                        {/*                    " It can be software. It can be a physical machine. It could be a combination of the two. A server is a really general term, and is something you will get more" +*/}
                        {/*                    " comfortable with the more you're around it."}/>*/}

                        {/*    <QnAElement question={"How does hello world relate to an app I know, like Microsoft Word?"}*/}
                        {/*                answer={"There is a long road between getting a console to print out a String and creating and maintaining a full scale production app like the one that sits in your dock." +*/}
                        {/*                    " Those apps require a lot more work than what one person can do. So for now just focus on useful programs that can be accomplished by a single individual."}/>*/}

                        {/*    <QnAElement question={"What are \"libraries\"? Should you be using them?"}*/}
                        {/*                answer={"Libraries are code that has been written by someone else. This code can be referenced from your code. This allows for code reuse." +*/}
                        {/*                    "How to bring a library into your code depends on what the environment you are working in looks like. Languages have a \"standard library\"" +*/}
                        {/*                    "this is made available usually when downloading the SDK. You will possibly then need to use import statements to bring in certain \"modules\"" +*/}
                        {/*                    "from the library and use them in your code. To start, you will not NEED to use any libraries besides what comes with the language, the standard library." +*/}
                        {/*                    "As you get going on more complex projects, you will want to look to libraries as they will enhance your functionality and shorten development time."} />*/}
                        {/*</div>*/}


                    </div>
                    <div className='flex justify-center'>
                        <img src={purpDev} alt="under development"/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export function QnAElement({question, answer}) {
    return (
        <div className='pb-10'>
            <p className='text-3xl text-white font-bold pb-5'>
                Q: {question}
            </p>

            <p className='text-3xl text-violet-400 font-bold'>
                A: {answer}
            </p>
        </div>
    )
}
