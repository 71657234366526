import React from 'react'
import '../styles/nav-bar.css'
import {NavLink} from "react-router-dom";

export class NavBar extends React.Component {
    render() {
        return (
            <div className="nav">
                <nav>
                    <ul className='sm:flex sm:justify-center text-center list-none text-base'>
                        <li className="nav-item hover:bg-purple-400 w-full">
                            <NavLink to='/' className='text-white'  activeClassName='active h-full' exact> Home 🏡 </NavLink>
                        </li>
                        <li className="nav-item hover:bg-purple-400 w-full">
                            <NavLink to='/studio' className='text-white'  activeClassName='active h-full' exact> My Studio 🛠</NavLink>
                        </li>
                        <li className="nav-item hover:bg-purple-400 w-full">
                            <NavLink to='/ltc' className='text-white' activeClassName='active h-full'> Learn to Code 🧠</NavLink>
                        </li>
                        {/*<li className="nav-item hover:bg-purple-400 w-full">*/}
                        {/*    <NavLink to='/articles' className='text-white' activeClassName='active h-full'> Whatcha Think 🤨</NavLink>*/}
                        {/*</li>*/}
                        {/*<li className="nav-item hover:bg-purple-400 w-full">*/}
                        {/*    <NavLink to='/subscribe' className='text-white' activeClassName='active h-full'> Subscribe 📜</NavLink>*/}
                        {/*</li>*/}
                    </ul>
                </nav>
            </div>

        )
    }

}

